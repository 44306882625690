/* eslint-disable arrow-body-style */
import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import { getSearchWith } from '../../common/helpers/queryHelpers';

export const SearchLink = ({
  children,
  params,
  ...props
}) => {
  const [searchParams] = useSearchParams();

  return (
    <Link to={{ search: getSearchWith(searchParams, params) }} {...props}>
      {children}
    </Link>
  );
};
