/* eslint-disable arrow-body-style */
import { httpClient } from '../clients/httpClient';

const upload = (payload, path) => {
  return httpClient.post(`/upload?path=${path}`, payload); // god bless my soul for this
};

export const uploadService = {
  upload,
};
