import * as Yup from 'yup';

export const loginValidationSchema = Yup.object().shape({
  email: Yup
    .string()
    .email('Невірно введено e-mail')
    .required('Пошта не може бути пустою'),
  password: Yup
    .string()
    .required('Пароль не може бути пустим')
    .min(4, 'Потрібно мінімум 4 символи'),
});

export const regValidationSchema = Yup.object().shape({
  username: Yup
    .string()
    .required('Поле не може бути пустим')
    .min(2, 'Потрібно мінімум 2 символи')
    .max(50, 'Не більше 50 символів'),
  email: Yup
    .string()
    .email('Невірно введено e-mail')
    .required('Поле не може бути пустим'),
  phonenumber: Yup
    .string()
    .required('Поле не може бути пустим'),
  password: Yup
    .string()
    .required('Поле не може бути пустим')
    .min(4, 'Потрібно мінімум 4 символи'),
});
