/* eslint-disable arrow-body-style */
import { ErrorMessage } from 'formik';

export const withErrorMessage = (WrappedComponent) => {
  return ({ ...props }) => (
    <>
      <WrappedComponent {...props} />

      <span className="form-text ms-2 mt-0 text-danger">
        <ErrorMessage name={props.name} />
      </span>
    </>
  );
};
