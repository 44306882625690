/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable react/self-closing-comp */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { MDBCheckbox, MDBContainer, MDBCol } from 'mdb-react-ui-kit';
import { useSearchParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';

import './TypePicker.css';
import { SearchLink } from '../SearchLink/SearchLink';
import { toggleFilter } from '../../common/helpers/queryHelpers';

const filtersArray = [
  { value: '1', label: 'Театри' },
  { value: '2', label: 'Концерти' },
  { value: '3', label: 'Stand-Up' },
  { value: '4', label: 'Музеї' },
];

export const TypePicker = () => {
  const params = [
    'filters',
  ];

  const [searchParams] = useSearchParams();
  const filters = searchParams.getAll('filters');

  return (
    <MDBContainer className="my-5 py-2 bg-white rounded-9 d-flex flex-md-row flex-column fs-5 text-dark justify-content-center">
      <MDBCol
        sm={12}
        md={2}
        className="d-flex flex-row mx-1 mx-lg-0"
      >
        <SearchLink params={{ [params]: [] }}>
          <MDBCheckbox
            wrapperClass="d-flex justify-self-start
         align-items-center"
            key={uuid()}
            label="Усі"
            labelClass="align-middle text-dark"
            checked={filters.length === filtersArray.length || filters.length === 0}
            readOnly
          />
        </SearchLink>
      </MDBCol>
      {filtersArray.map(({ value, label }) => (
        <MDBCol
          sm={12}
          md={2}
          className="d-flex flex-row mx-1 mx-lg-0"
          key={value}
        >
          <SearchLink params={{ [params]: toggleFilter(filters, value) }}>
            <MDBCheckbox
              wrapperClass="d-flex justify-self-start
          align-items-center"
              key={uuid()}
              label={label}
              labelClass="align-middle text-dark"
              id={label}
              checked={filters.includes(value)}
              readOnly
            />
          </SearchLink>
        </MDBCol>
      ))}
    </MDBContainer>
  );
};
