/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { monthNames, dayNames } from '../constants/layouts';

export const dateTimeChanger = (datetime) => {
  const date = new Date(datetime);

  return {
    day: date.getDate(),
    month: monthNames[date.getMonth()],
    weekday: dayNames[date.getDay()],
    time: `${date.getHours()}:${addZeros(date.getMinutes())}`,
  };
};

export const addZeros = num => (num < 10 ? num = `0${num}` : num);

export const normalizeDate = ({ day, weekday, month }) => {
  const normalizedDate = `${day} ${month}, ${weekday}`;

  return normalizedDate;
};
