import React from 'react';
import './PurchaseButton.css';

export const PurchaseButton = ({ label }) => (
  <button
    type="button"
    className="button__purchase-red"
  >
    {label}
  </button>
);
