/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { accessTokenService } from '../../../services/accessTokenService';
import { authService } from '../../../services/authService';
import { uploadService } from '../../../services/uploadService';
import { favoriteService } from '../../../services/favoriteService';

const initialState = {
  user: {
    id_user: '',
    username: '',
    email: '',
    phonenumber: '',
    token: '',
  },
  favorite: [],
  logged: false,
  loading: false,
  error: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    assignUser: (state, action) => {
      state.user = action.payload;
    },
    leaveUser: (state, action) => {
      accessTokenService.remove();
      state.user = initialState.user;
      state.favorite = [];
      state.loading = false;
      state.error = '';
      state.logged = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.user = { ...action.payload.userData };
      state.favorite = [...action.payload.fav];
      // state.user = action.payload;
      state.logged = true;
      accessTokenService.save(action.payload.userData.token);
      state.loading = false;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.error = 'Невірний e-mail або пароль';
      state.error = action.error.code === 'ERR_BAD_REQUEST'
        ? 'Невірний e-mail або пароль'
        : 'Помилка під час логіну. Спробуйте ще раз';
      state.loading = false;
    });
    builder.addCase(register.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.user = { ...action.payload };
      accessTokenService.save(action.payload.token);
      state.loading = false;
    });
    builder.addCase(register.rejected, (state, action) => {
      state.error = action.error.code === 'ERR_BAD_REQUEST'
        ? 'Такий користувач вже існує'
        : 'На жаль, реєстрація не вдалась. Спробуйте ще раз';
      state.loading = false;
    });
    builder.addCase(addFavorite.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addFavorite.fulfilled, (state, action) => {
      state.favorite.push(action.payload);
      state.loading = false;
    });
    builder.addCase(addFavorite.rejected, (state, action) => {
      state.error = 'Виникла помилка при додаванні в улюблене';
      state.loading = false;
    });
    builder.addCase(removeFavorite.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(removeFavorite.fulfilled, (state, action) => {
      state.favorite.splice(state.favorite.findIndex(event => event === action.payload), 1);
      state.loading = false;
    });
    builder.addCase(removeFavorite.rejected, (state, action) => {
      state.error = 'Виникла помилка при додаванні в улюблене';
      state.loading = false;
    });
    builder.addCase(refresh.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(refresh.fulfilled, (state, action) => {
      state.user = { ...action.payload.userData };
      state.favorite = [...action.payload.favoriteIds];
      state.logged = true;
      state.loading = false;
    });
    builder.addCase(refresh.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  assignUser,
  leaveUser,
} = userSlice.actions;

export default userSlice.reducer;

export const uploadPhoto = createAsyncThunk(
  '/upload',
  uploadService.upload,
);

export const login = createAsyncThunk(
  '/login',
  authService.login,
);

export const register = createAsyncThunk(
  '/register',
  authService.register,
);

export const refresh = createAsyncThunk(
  '/refresh',
  authService.refresh,
);

export const addFavorite = createAsyncThunk(
  '/favorite/addFav',
  favoriteService.addFavorite,
);

export const removeFavorite = createAsyncThunk(
  '/favorite/removeFav',
  favoriteService.removeFavorite,
);

export const getFavorite = createAsyncThunk(
  '/favorite/getFavs',
  favoriteService.getFavorites,
);
