import { authClient } from '../clients/authClient';

async function register(payload) {
  return authClient.post('/register', payload);
}

async function login(payload) {
  return authClient.post('/login', payload);
}

async function refresh() {
  return authClient.get('/refresh');
}

async function logout() {
  return authClient.post('/logout');
}

async function getInfo() {
  return authClient.get('/me');
}

async function resetPassword(email) {
  return authClient.post('/reset', { email });
}

export const authService = {
  register,
  getInfo,
  login,
  refresh,
  logout,
  resetPassword,
};
