/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React from 'react';
import { Outlet } from 'react-router-dom';

export const AdminMainLayout = () => {
  return (
    <main className="min-vh-100 d-flex justify-content-center align-items-center bg-white">
      <Outlet />
    </main>
  );
};
