/* eslint-disable arrow-body-style */
import React from 'react';
import { MDBContainer } from 'mdb-react-ui-kit';
import { EventItemInfo } from '../../components/EventItemInfo';

export const EventInfoPage = () => {
  return (
    <MDBContainer className="h-100 text-center text-dark p-0">
      <EventItemInfo />
    </MDBContainer>
  );
};
