/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable arrow-body-style */
import React from 'react';
import {
  MDBCard,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
} from 'mdb-react-ui-kit';

import { dateTimeChanger } from '../../common/helpers/eventDateChanger';
import { Badge } from '../Badge';
import { PurchaseButton } from '../PurchaseButton';
import { FavoriteButton } from '../FavoriteButton/FavoriteButton';

import './EventItem.css';

import { COVER_PATH } from '../../common/constants/main';

export const EventItem = ({ eventInfo, isFavorite }) => {
  const dateInfo = dateTimeChanger(eventInfo.event_date);

  return (
    <a href={`/event/${eventInfo.id_event}`} alt="">
      <MDBCard className="event__card-wrapper rounded-8">
        <MDBRow>
          <MDBCol md="2" className="d-flex flex-row flex-md-column justify-content-center align-items-center text-center my-2">
            <h2 className="display-1 lh-half m-0 pe-1">{dateInfo.day}</h2>
            <p className="pe-1 m-0 lh-1">{dateInfo.month},</p>
            <p className="pe-1 m-0 lh-1 text-muted">{dateInfo.weekday}</p>
            <p className="pe-1 m-0 lh-1 text-muted">{dateInfo.time}</p>
          </MDBCol>
          <MDBCol md="4" className="p-0 px-3 px-md-0 d-flex align-items-center">
            <img alt="" className="event__item-image rounded-2" src={`${COVER_PATH}/${eventInfo.coverImage}`} />
          </MDBCol>
          <MDBCol md="6" className="ps-4 py-3 pe-5 d-flex flex-column justify-content-center align-items-start">
            <MDBCardTitle className="mt-1 mb-3 fs-3 text-break text-start">{eventInfo.event_name}</MDBCardTitle>
            <Badge className="mt-1" type={eventInfo.event_type} />
            <MDBCardText className="my-1 text-muted text-break text-start">
              {eventInfo.event_place}
            </MDBCardText>
            {eventInfo.event_desc ? (
              <MDBCardText className="event__item-desc my-1 text-start ">
                {eventInfo.event_desc}
              </MDBCardText>
            ) : null}
            <MDBCardText className="mt-1 mb-2 fs-5">
              {eventInfo.event_price}₴
            </MDBCardText>
            <div className="block__buybutton">
              <PurchaseButton label="Купити квиток" />
              <FavoriteButton eventId={eventInfo.id_event} isFavorite={isFavorite} />
            </div>
          </MDBCol>
        </MDBRow>
      </MDBCard>
    </a>
  );
};
