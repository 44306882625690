/* eslint-disable arrow-body-style */
import React from 'react';
import { badgeTitles } from '../../common/constants/layouts';
import './Badge.css';

export const Badge = ({ type }) => {
  const currentType = parseInt(type, 10) - 1;

  return (
    <div className={`layout__badge badge-bgcolor${currentType}`}>{badgeTitles[currentType]}</div>
  );
};
