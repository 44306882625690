import React, { useState, useEffect } from 'react';
// import { useSelector, useDispatch } from 'react-redux';

import { authService } from '../../services/authService';

export const FavoritePage = () => {
  // const dispatch = useDispatch();
  // const [events, setEvents] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // eslint-disable-next-line consistent-return
  useEffect(async() => {
    try {
      await authService.getInfo();

      setIsLoading(true);
    } catch (error) {
      return error;
    } finally {
      setIsLoading(false);
    }
  }, []);

  return (
    isLoading
      ? <div>Loading...</div>
      : (
        <div>
          <p>test</p>
        </div>
      )
  );
};
