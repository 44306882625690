/* eslint-disable arrow-body-style */
import { httpClient } from '../clients/httpClient';

const fetchAllEvents = (userId) => {
  return httpClient.get(`/events/favorite?id=${userId}`);
};

const fetchEvents = (page, types, query) => {
  return httpClient.get(`/events/search?page=${page}&types=${types}&query=${query}`);
};

const fetchEvent = (id) => {
  return httpClient.get(`/events/event/${id}`);
};

const fetchEventsForSlider = (limit) => {
  return httpClient.get(`/events/slider?limit=${limit}`);
};

const createEvent = (payload) => {
  return httpClient.post('/events', payload);
};

export const publicService = {
  fetchAllEvents,
  fetchEvent,
  fetchEvents,
  fetchEventsForSlider,
  createEvent,
};
