/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from '@uidotdev/usehooks';

import { EventsTable } from '../../components/EventsTable';
import { NextMonthArea } from '../../components/NextMonthArea';
import { TypePicker } from '../../components/TypePicker';
import { Slider } from '../../components/Slider';
import { EVENTS_SEARCH_DEBOUNCE_MS } from '../../common/constants/main';

export const HomePage = () => {
  const [searchParams] = useSearchParams();
  const debounceQuery = useDebounce(
    searchParams.get('query' ?? ''),
    EVENTS_SEARCH_DEBOUNCE_MS,
  );

  const typesQuery = searchParams.getAll('filters');

  return (
    <div className="text-primary">
      <Slider />
      <TypePicker />
      <EventsTable typesQuery={typesQuery} query={debounceQuery} />
      <NextMonthArea />
    </div>
  );
};
