/* eslint-disable camelcase */
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit';
import React from 'react';
import { normalizeDate, dateTimeChanger } from '../../common/helpers/eventDateChanger';
import { PurchaseButton } from '../PurchaseButton';

import './SliderUnderInfo.css';

export const SliderUnderInfo = ({ payload }) => {
  const { id_event, event_name, event_place, event_price, event_date } = payload;
  const normalDate = normalizeDate(dateTimeChanger(event_date));

  return (
    <MDBContainer className="slider__undertext w-100 d-flex flex-sm-row flex-column align-items-sm-between align-items-center text-center text-sm-start d-md-none m-0 mt-4 rounded-7 text-dark p-3">
      <MDBCol xs={12} sm={2} className="text-center mx-auto">
        <MDBRow>
          <h1 className="p-0 text-break">{event_name}</h1>
        </MDBRow>
      </MDBCol>
      <MDBCol xs={12} sm={2} className="text-center mx-auto">
        <MDBRow>
          <p className="fs-5 p-0 lh-1 pb-2 text-break">{normalDate}</p>
          <p className="fs-5 px-5 px-sm-1 text-muted lh-1 pb-1 text-break">{event_place}</p>
        </MDBRow>
      </MDBCol>
      <MDBCol xs={12} sm={2} className="text-center mx-auto">
        <MDBRow>
          <p className="fs-5 p-0 text-break">{`${event_price}₴`}</p>
        </MDBRow>

      </MDBCol>
      <MDBCol xs={12} sm={2} className="text-center mx-auto">
        <MDBRow>
          <a href={`/event/${id_event}`} className="p-0">
            <PurchaseButton label="Купити" />
          </a>
        </MDBRow>

      </MDBCol>
    </MDBContainer>
  );
};
