import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { SkeletonTheme } from 'react-loading-skeleton';
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query';

import { App } from './App';
import { store } from './redux/store';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
    },
  },
});

export const Root = () => (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <Router>
        <SkeletonTheme baseColor="#FFF" highlightColor="#F5F5F5">
          <App />
        </SkeletonTheme>
      </Router>
    </QueryClientProvider>
  </Provider>
);
