import { httpClient } from '../clients/httpClient';

async function addFavorite(payload) {
  return httpClient.post('/favorite', payload);
}

async function removeFavorite(payload) {
  return httpClient.put(`/favorite`, payload);
}

async function getFavorites(userId) {
  return httpClient.get(`/favorite?id=${userId}`);
}

export const favoriteService = {
  addFavorite,
  removeFavorite,
  getFavorites,
};
