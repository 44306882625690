/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBSpinner,
} from 'mdb-react-ui-kit';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useQuery } from 'react-query';
import { usePage } from '../../hooks/usePage';

import { EventItem } from '../EventItem/EventItem';
import { publicService } from '../../services/publicService';
import { ErrorElement } from '../ErrorElement/ErrorElement';
import { noDateFound } from '../../common/constants/errorMessages';

export const EventsTable = ({ typesQuery, query }) => {
  const [events, setEvents] = useState([]);
  const [eventsAmount, setEventsAmount] = useState(0);
  const { page, increasePage, resetPages } = usePage();
  const { favorite } = useSelector(state => state.user);

  useEffect(() => {
    resetPages();
  }, [typesQuery, query]);

  const { isLoading, isError, isSuccess, isFetching } = useQuery({
    queryKey: ['events', page, query, typesQuery],
    // eslint-disable-next-line space-before-function-paren
    queryFn: async () => {
      const { data, count } = await publicService.fetchEvents(page, typesQuery, query);

      if (page === 1) {
        setEvents(data);
        setEventsAmount(count);

        return;
      }

      setEvents(prevState => [...prevState, ...data]);
    },

  });

  const hasMore = eventsAmount > events.length;

  return (
    <MDBContainer className="p-0">
      <MDBCol className="text-center">
        <MDBRow className="my-5 text-center"><h1 className="fs-3 color-dark">Дозвілля у вересні</h1></MDBRow>
        {isSuccess && events.length === 0 ? <ErrorElement errorMessage={noDateFound} /> : null}
        {isLoading && page === 1 ? <MDBSpinner color="dark" className="mb-5" /> : (
          <InfiniteScroll
            dataLength={events.length}
            next={increasePage}
            hasMore={hasMore}
            scrollThreshold={0.75}
            className="p-3"
            loader={<MDBSpinner />}
          >
            {events.map(event => (
              <MDBRow className="mb-5 mx-1" key={event.id_event}>
                <EventItem eventInfo={event} isFavorite={favorite.some(item => item === event.id_event)} />
              </MDBRow>
            ))}
          </InfiniteScroll>
        )}
      </MDBCol>
    </MDBContainer>
  );
};
