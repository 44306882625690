/* eslint-disable import/no-extraneous-dependencies */
import { configureStore } from '@reduxjs/toolkit';

import eventsReducer from './features/eventsSlice';
import userReducer from './features/user/userSlice';

export const store = configureStore({
  reducer: {
    events: eventsReducer,
    user: userReducer,
  },
});
