import React from 'react';
import Logo from '../../assets/images/logos/leisure-headlogo.svg';

import './HeaderLogo.css';

export const HeaderLogo = ({ screenWidth }) => (
  <div className="headlogo-container">
    <div className="headlogo-container-img">
      <img src={Logo} alt="headlogo" className="headlogo-icon" />
    </div>
    {
      screenWidth >= 992
        ? (
          <div className="headlogo_container-text">
            <p className="headlogo_text-big">Дозвілля</p>
            <p className="headlogo_text-small">Найбільш очікувані події Дніпра</p>
          </div>
        )
        : null
    }
  </div>
);
