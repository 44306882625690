/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React from 'react';
import { MDBContainer, MDBCol, MDBRow } from 'mdb-react-ui-kit';
import { normalizeDate, dateTimeChanger } from '../../common/helpers/eventDateChanger';
import { PurchaseButton } from '../PurchaseButton';

import './SliderInfo.css';

export const SliderInfo = ({ payload }) => {
  const { id_event, event_name, event_place, event_price, event_date } = payload;
  const normalDate = normalizeDate(dateTimeChanger(event_date));

  return (
    <div className="slider__info text-dark d-md-flex d-none flex-column p-4">
      <MDBRow><h1 className="slider__text-truncate fs-2 text-break text-start mb-2">{event_name}</h1></MDBRow>
      <MDBRow><p className="fs-4 lh-1 text-break text-start">{normalDate}</p></MDBRow>
      <MDBRow><p className="slider__text-truncate fs-4 lh-1 text-break text-start mb-2">{event_place}</p></MDBRow>
      <MDBRow><p className="fs-4 text-break text-start mb-5">{`${event_price}₴`}</p></MDBRow>
      <MDBRow className="text-start">
        <a href={`/event/${id_event}`}>
          <PurchaseButton label="Купити" />
        </a>
      </MDBRow>
    </div>
  );
};
