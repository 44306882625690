export const getSearchWith = (currentParams, paramsToUpdate) => {
  const newParams = new URLSearchParams(currentParams.toString());

  Object.entries(paramsToUpdate)
    .forEach(([key, value]) => {
      if (value === null) {
        newParams.delete(key);
      } else if (Array.isArray(value)) {
        // we delete the key to remove old values
        newParams.delete(key);

        value.forEach((part) => {
          newParams.append(key, part);
        });
      } else {
        newParams.set(key, value);
      }
    });

  return newParams.toString();
};

export const toggleFilter = (filters, newFilters) => (
  filters.includes(newFilters)
    ? filters.filter(type => type !== newFilters)
    : [...filters, newFilters]
);
