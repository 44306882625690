import React from 'react';
import { MDBContainer, MDBCol, MDBRow, MDBBtn } from 'mdb-react-ui-kit';
import { nextMonths } from '../../common/constants/layouts';

export const NextMonthArea = () => (
  <MDBContainer className="w-100 text-center d-flex justify-content-center flex-column">
    <h1 className="fs-3 color-dark">Дозвілля в наступних місяцях:</h1>
    <MDBRow className="d-flex mx-md-0 mx-3 mt-4 mb-md-5 mb-2  flex-md-row flex-col justify-content-center">
      {nextMonths.map(item => (
        <MDBCol sm={8} md={4} lg={3} xs={12} key={item}>
          <MDBBtn rounded color="light" size="lg" className=" px-5 mx-xs-0 mx-md-4 my-3 my-md-0 months__button fs-5 ">{item}</MDBBtn>
        </MDBCol>
      ))}
    </MDBRow>
  </MDBContainer>
);
