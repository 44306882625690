import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { MDBIcon } from 'mdb-react-ui-kit';

import { PopupLogin } from './PopupLogin';
import { PopupRegister } from './PopupRegister/PopupRegister';

import './PopupAuth.css';

export const PopupAuth = () => {
  const [isRegister, setIsRegister] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  return (
    <Popup
      trigger={(
        <button type="button" className="d-flex flex-row align-items-center border-0 p-0 mb-2 mb-lg-0">
          <MDBIcon far icon="user-circle" size="lg" />
          <p className="m-0 ms-1">Увійти</p>
        </button>
      )}
      onOpen={() => {
        setIsRegister(false);
        setIsSuccess(true);
      }}
      className=""
      onClose={() => setIsSuccess(false)}
      open={isSuccess}
      modal
      nested
      // lockScroll={isSuccess}
    >
      {close => (
        <div className="popup__modal popup-content my-1 mx-4 ">
          <button type="button" className="popup__button-close" onClick={close}>
            <MDBIcon fas icon="times" />
          </button>
          {isRegister
            ? <PopupRegister setIsRegister={setIsRegister} setIsSuccess={setIsSuccess} />
            : <PopupLogin setIsRegister={setIsRegister} setIsSuccess={setIsSuccess} />
          }
        </div>
      )}
    </Popup>
  );
};
