import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useMutation } from 'react-query';

import { PublicMainLayout } from './components/PublicMainLayout';
import { HomePage } from './pages/HomePage/HomePage';
import { AdminMainLayout } from './components/AdminMainLayout';
import { AdminPage } from './pages/AdminPage/AdminPage';
import { EventInfoPage } from './pages/EventInfoPage';
import { FavoritePage } from './pages/FavoritePage';
import { refresh } from './redux/features/user/userSlice';

import './styles/global.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

export const App = () => {
  const dispatch = useDispatch();

  const checkMutation = useMutation({
    mutationFn: async() => {
      dispatch(refresh());
    },
  });

  useEffect(() => {
    checkMutation.mutate();
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<PublicMainLayout />}>
          <Route index element={<HomePage />} />
          <Route path="/event/:id" element={<EventInfoPage />} />
          <Route path="/favorite" element={<FavoritePage />} />
        </Route>

        <Route path="admin" element={<AdminMainLayout />}>
          <Route index element={<AdminPage />} />
        </Route>
      </Routes>
    </>
  );
};
