import React, { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { MDBInputGroup, MDBIcon } from 'mdb-react-ui-kit';
import { getSearchWith } from '../../common/helpers/queryHelpers';

import './NavSearchRow.css';

export const NavSearchRow = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [query, setQuery] = useState('');

  useEffect(() => {
    setQuery(searchParams.get('query') ?? '');
  }, []);

  const handleQueryChange = (changeEvent) => {
    setSearchParams(
      getSearchWith(
        searchParams,
        // eslint-disable-next-line no-useless-computed-key
        { ['query']: changeEvent.target.value || null },
      ),
    );
    setQuery(changeEvent.target.value);
  };

  return (
    <MDBInputGroup tag="form" className="mx-0 mt-2 mt-lg-0 ms-lg-4 me-lg-2 w-75" noBorder>
      <span className="input-group-text border border-end-0 bg-white px-md-2 pe-1">
        <MDBIcon fas icon="search" />
      </span>
      <input
        className="form-control px-1 border border-start-0 navbar__search-border fs-small"
        placeholder="Пошук"
        aria-label="Search"
        type="Search"
        value={query}
        onChange={handleQueryChange}
      />
    </MDBInputGroup>
  );
};
