/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useField } from 'formik';
import { withErrorMessage } from '../withErrorMessage';

import './EventImageUploader.css';

export const EventImageUploader = withErrorMessage(({ name }) => {
  const [, meta, helpers] = useField(name);

  const [previewFile, setPreviewFile] = useState(null);
  const { value: files } = meta;
  const { setValue: setFiles } = helpers;

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    type: 'file',
    accept: {
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
    },
    onDrop: (acceptedFiles, fileRejection) => {
      setPreviewFile(URL.createObjectURL(acceptedFiles[0]));
      setFiles(acceptedFiles[0]);
    },
  });

  // Need to revoke data urls to avoid memory leak
  useEffect(() => () => URL.revokeObjectURL(previewFile), []);

  return (
    <section className="uploader__container">
      <div {...getRootProps({ className: 'uploader__dropzone w-100 h-100' })}>
        <input {...getInputProps()} />
        {previewFile === null ? <p>Перетягніть або оберіть картинку</p> : (
          <img
            src={previewFile}
            alt=""
            className="image__uploader-img"
            onLoad={() => {
              URL.revokeObjectURL(previewFile.preview);
            }
            }

          />
        )}
      </div>
      {files !== null && (
        <button
          className="btn btn-dark w-50 mt-3"
          type="button"
          onClick={() => {
            setPreviewFile(null);
            setFiles(null);
          }}
        >
          Очистити
        </button>
      )}
    </section>
  );
});
