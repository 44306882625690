/* eslint-disable space-before-function-paren */
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { loginValidationSchema } from '../../../validation/authValidation';
import { AdminInputTextField as InputField } from '../../AdminInputTextField';
import { login } from '../../../redux/features/user/userSlice';

import './PopupLogin.css';

export const PopupLogin = ({ setIsRegister, setIsSuccess }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);

  const { error } = useSelector(state => state.user);

  const handelClick = () => {
    setIsRegister(true);
  };

  const handleSubmit = async (data) => {
    dispatch(login(data))
      .then((item) => {
        // rework error handling (maybe switch to toastify)
        if (item.meta.requestStatus === 'rejected') {
          setIsError(true);
        }

        if (item.meta.requestStatus === 'fulfilled') {
          setIsSuccess(false);
          navigate('/');
        }
      });
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <div className="d-flex flex-column login__form p-5 border border-dark rounded-9 bg-white">
        <h1 className="fs-2 fw-bold text-center mb-4">Вхід</h1>
        <Formik
          validationSchema={loginValidationSchema}
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={handleSubmit}
        >
          <Form className="d-flex flex-column">
            <InputField
              id="email"
              name="email"
              placeholder="Електронна пошта"
              type="email"
              className="login__form-input py-2 px-4 mt-2 mb-2 fs-small"
            />
            <InputField
              id="password"
              name="password"
              type="password"
              placeholder="Пароль"
              className="login__form-input py-2 px-4 mt-2 mb-2 fs-small"
            />

            <button type="submit" className="border-0 login__button-color rounded-7 py-2 mt-1 text-dark fs-small">Увійти</button>
          </Form>
        </Formik>
        <div className="mt-2 text-center">
          <button type="button" className="border-0 btn btn-tertiary py-1" onClick={handelClick}>Зареєструватися</button>
          {
            isError
              ? (<p className="fs-small text-danger">{error}</p>)
              : null
          }
        </div>
      </div>
    </div>
  );
};
