import * as Yup from 'yup';

export const eventsValidSchema = Yup.object().shape({
  title: Yup
    .string()
    .required('Назва івенту не може бути пустою')
    .min(1, 'Введіть мінімум 1 символ'),
  place: Yup.string().required('Місце проведення не може бути порожнім')
    .min(4, 'Потрібно мінімум 4 символи'),
  // type: Yup.string().required('Ім\'я не може бути порожнім')
  //   .min(2, 'Потрібно мінімум 2 символи').max(20, 'Максимум 20 символів'),
  // dateTime: Yup.string().required('Прізвище не може бути порожнім')
  //   .min(2, 'Потрібно мінімум 2 символи').max(20, 'Максимум 20 символів'),
  priceFrom: Yup.string().required('Ціна не може бути порожньою')
    .min(1, 'Потрібно мінімум 1 символ').max(5, 'Максимум 5 символів'),
  priceTo: Yup.string().required('Ціна не може бути порожньою')
    .min(1, 'Потрібно мінімум 1 символ').max(5, 'Максимум 5 символів'),
  // description: Yup.string().required('Прізвище не може бути порожнім')
  //   .min(2, 'Потрібно мінімум 2 символи').max(20, 'Максимум 20 символів'),
  coverFile: Yup.string().required('Оберіть файл!'),
  sliderFile: Yup.string().required('Оберіть файл!'),
});
