import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../Header';
import { Footer } from '../Footer';

export const PublicMainLayout = () => (
  <>
    <div className="w-100">
      <Header />

      <main className="h-100 flex-grow-1 pb-3">
        <div className="container p-0">
          <Outlet />
        </div>
      </main>

    </div>
    <Footer className="mt-auto" />
  </>

);
