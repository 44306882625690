/* eslint-disable arrow-body-style */
import React from 'react';
import { Field, useField } from 'formik';
import { withErrorMessage } from '../withErrorMessage';

export const AdminInputTextField = withErrorMessage(({ name, className, ...props }) => {
  const [input] = useField(name);
  const { onChange } = input;

  return (
    <div className="d-flex flex-column">
      <Field
        name={name}
        className={className}
        onChange={onChange}
        {...props}
      />
    </div>
  );
});
