import { useState } from 'react';

export const usePage = (startPage = 1) => {
  const [page, setPage] = useState(startPage);

  const increasePage = () => setPage(prevPage => prevPage + 1);

  const resetPages = () => setPage(startPage);

  return {
    page,
    increasePage,
    resetPages,
  };
};
