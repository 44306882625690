/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react';
import DateTimePicker from 'react-datetime-picker';
import { useField } from 'formik';

export const AdminInputDateTime = ({ name }) => {
  const [input, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;

  useEffect(() => {
    setValue(new Date());
  }, []);

  return (
    <DateTimePicker
      onChange={setValue}
      value={value}
      name="dateTime"
      minDate={new Date()}
      className="w-75"
      locale="uk"
    />
  );
};
