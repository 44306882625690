import React from 'react';
import {
  MDBFooter,
  MDBCol,
  MDBRow,
  MDBIcon,
} from 'mdb-react-ui-kit';
import { v4 as uuid } from 'uuid';

import { phoneNumbers } from '../../common/constants/layouts';

import logo from '../../assets/images/logos/ednipro-logo.svg';
import './Footer.css';

export const Footer = () => (
  <MDBFooter
    bgColor="white"
    className="d-flex justify-content-center align-items-center footer__container-height p-2"
  >
    <MDBRow className="w-100">
      <MDBCol
        md="3"
        className="d-flex justify-content-center align-items-center"
      >
        <img className="footer__img-size" src={logo} alt="" />
      </MDBCol>

      <MDBCol md="6" className="d-flex justify-content-around align-items-center flex-column flex-md-row mt-3 mt-md-0">
        <p className="">Ми завжди на зв&apos;язку:</p>
        {
          phoneNumbers.map(item => (
            <a className="text-dark px-2" href={`tel: ${item}`} key={uuid()}>{item}</a>
          ))
        }
      </MDBCol>

      {/* CHANGE TO MAP */}
      <MDBCol md="3" className="d-flex justify-content-center align-items-center mt-3 mt-md-0">
        <a className="text-dark" target="blank" href="http://t.me/kp_ednipro">
          <MDBIcon className="mx-4 icon__link" fab icon="telegram-plane" size="2x" />
        </a>
        <a className="text-dark" target="blank" href="https://www.facebook.com/kpednipro/">
          <MDBIcon className="mx-4 icon__link" fab icon="facebook-f" size="2x" />
        </a>
        <a className="text-dark" target="blank" href="https://www.instagram.com/ednipro/">
          <MDBIcon className="mx-4 icon__link" fab icon="instagram" size="2x" />
        </a>
      </MDBCol>
    </MDBRow>
  </MDBFooter>
);
