/* eslint-disable space-before-function-paren */
import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { MDBRow, MDBCol } from 'mdb-react-ui-kit';
import { useDispatch, useSelector } from 'react-redux';
// import { useLocation, useNavigate } from 'react-router-dom';

import { AdminInputTextField as InputField } from '../../AdminInputTextField';
import { regValidationSchema } from '../../../validation/authValidation';
import { register } from '../../../redux/features/user/userSlice';

import './PopupRegister.css';

export const PopupRegister = ({ setIsRegister, setIsSuccess }) => {
  const dispatch = useDispatch();
  const { error } = useSelector(state => state.user);
  const [isError, setIsError] = useState(false);

  const handleSubmit = async (data) => {
    dispatch(register(data))
      .then((item) => {
        if (item.meta.requestStatus === 'rejected') {
          setIsError(true);
        }

        if (item.meta.requestStatus === 'fulfilled') {
          setIsSuccess(false);
        }
      });
  };

  const handelClick = () => {
    setIsRegister(false);
  };

  return (
    <div className="form-width text-center px-3 pt-4 pt-md-5 pb-3 border border-dark rounded-9 bg-white">
      <MDBRow className="m-auto w-75 mb-3 mb-md-4">
        <h1 className="fs-2 text-center mb-1 fw-bold">Вітаємо!</h1>
        <p className="form__title-text">Для користування особистим кабінетом пройдіть експрес-реєстрацію</p>
      </MDBRow>
      <Formik
        validationSchema={regValidationSchema}
        initialValues={{
          username: '',
          email: '',
          phonenumber: '',
          password: '',
        }}
        onSubmit={handleSubmit}
      >
        <Form className="d-flex flex-column">
          <MDBRow>
            <MDBCol md={12} className="mb-0 mb-sm-1 mb-md-2 d-flex flex-md-row flex-column justify-content-around align-items-center">
              <div className="field mb-2 mb-md-0">
                <InputField
                  id="username"
                  name="username"
                  placeholder="Ім'я та прізвище"
                  type="username"
                  className="register__form-input py-2 mb-1 px-2 fs-small text-center"
                />
              </div>
              <div className="field mb-2 mb-md-0">
                <InputField
                  id="phonenumber"
                  name="phonenumber"
                  placeholder="Номер телефону"
                  type=""
                  className="register__form-input py-2 mb-1 px-4 fs-small text-center"
                />
              </div>
            </MDBCol>
            <MDBCol md={12} className="mb-1 d-flex flex-md-row flex-column justify-content-around align-items-center">
              <div className="field mb-2">
                <InputField
                  id="email"
                  name="email"
                  type="email"
                  placeholder="Пошта"
                  className="register__form-input py-2 mb-1 px-4 fs-small text-center"
                />
              </div>
              <div className="field mb-2">
                <InputField
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Пароль"
                  className="register__form-input py-2 mb-1 px-4 fs-small text-center"
                />
              </div>
            </MDBCol>
          </MDBRow>
          <button type="submit" className="form__button border-0 login__button-color rounded-7 py-2 text-dark m-auto fs-small">Зареєструватися</button>
        </Form>
      </Formik>
      <div className="mt-2">
        <button type="button" className="border-0 btn btn-tertiary py-1" onClick={handelClick}>Вже маю акаунт</button>
        {
          isError
            ? (<p className="fs-small text-danger">{error}</p>)
            : null
        }
      </div>
    </div>
  );
};
