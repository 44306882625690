/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectFade } from 'swiper/modules';
import { MDBContainer, MDBRow } from 'mdb-react-ui-kit';
import { useQuery } from 'react-query';
import { publicService } from '../../services/publicService';
import { SliderUnderInfo } from '../SliderUnderInfo';

import { SliderInfo } from '../SliderInfo';

import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import './Slider.css';

import { EVENTS_LIMIT_FOR_SLIDER, SLIDER_PATH } from '../../common/constants/main';

export const Slider = () => {
  const [eventsForSlider, setEventsForSlider] = useState([]);
  const [currentEventId, setCurrentEventId] = useState(0);

  const { isSuccess, isError } = useQuery({
    // eslint-disable-next-line space-before-function-paren
    queryFn: async () => {
      const { data } = await publicService.fetchEventsForSlider(EVENTS_LIMIT_FOR_SLIDER);

      setEventsForSlider(data);
    },
  });

  return (
    <MDBContainer>
      {isSuccess && (
        <>
          <Swiper
            navigation
            loop
            modules={[EffectFade, Navigation]}
            slidesPerView={1}
            spaceBetween={30}
            effect="fade"
            className="mySwiper"
            onSlideChange={currSlide => setCurrentEventId(currSlide.activeIndex)}
          >
            {eventsForSlider.map((event, index) => (
              <SwiperSlide key={event.id_event} virtualIndex={index}>
                <img src={`${SLIDER_PATH}/${event.sliderImage}`} alt="" />
                <SliderInfo payload={event} />
              </SwiperSlide>
            ))}
          </Swiper>
          <SliderUnderInfo payload={eventsForSlider[currentEventId]} />
        </>
      )}
    </MDBContainer>
  );
};
