/* eslint-disable camelcase */
import React, { useState } from 'react';
import { MDBIcon } from 'mdb-react-ui-kit';
import { useDispatch, useSelector } from 'react-redux';
import { addFavorite, removeFavorite } from '../../redux/features/user/userSlice';

export const FavoriteButton = ({ eventId, isFavorite }) => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
  const [fav, setFavorite] = useState(isFavorite);

  const handleClick = () => {
    const payload = { userId: user.id_user, eventId };

    setFavorite(!fav);

    if (fav) {
      dispatch(removeFavorite(payload));
    } else {
      dispatch(addFavorite(payload));
    }
  };

  return (
    <button onClick={handleClick} type="button" className="block__buybutton-heart">
      {fav
        ? <MDBIcon fas icon="heart" size="lg" className="text-danger" />
        : <MDBIcon far icon="heart" size="lg" />}
    </button>
  );
};
