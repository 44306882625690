/* eslint-disable space-before-function-paren */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */

import React, { useState } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import { MDBContainer, MDBRow, MDBCol, MDBBtn } from 'mdb-react-ui-kit';
import Select from 'react-select';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

import { eventsValidSchema } from '../../validation/eventsValidation';
import { AdminInputTextField as InputField } from '../AdminInputTextField';
import { AdminInputDateTime as DateTime } from '../AdminInputDateTime/AdminInputDateTime';
import { uploadPhoto } from '../../redux/features/user/userSlice';
import { publicService } from '../../services/publicService';
import { uploadService } from '../../services/uploadService';
import { EventImageUploader } from '../EventImageUploader';

import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';
import './AdminEventHandling.css';

const FILE_PATH = '/slider';

export const AdminEventHandling = () => {
  const dispatch = useDispatch();

  const options = [
    { value: 1, label: 'Театри' },
    { value: 2, label: 'Концерти' },
    { value: 3, label: 'Stand-Up' },
    { value: 4, label: 'Музеї' },
  ];

  const initialValues = {
    title: '',
    dateTime: '',
    type: '',
    place: '',
    desc: null,
    priceFrom: '',
    priceTo: '',
    coverFile: null,
  };

  const createEvent = async (payload) => {
    await publicService.createEvent(payload);
  };

  const { mutate, isLoading, isError, error } = useMutation(createEvent);

  const handleMutationOnSubmit = async (values) => {
    const formData = new FormData();

    formData.append('image', values.coverFile);

    uploadService.upload(formData, FILE_PATH).then((data) => {
      mutate({
        payload: { ...values, coverFile: data },
      });
    });
  };

  return (
    <MDBContainer className="h-100 p-4">
      <Formik
        initialValues={initialValues}
        validationSchema={eventsValidSchema}
        onSubmit={(values, { setErrors }) => {
          // dispatch(uploadPhoto(values.file));
          handleMutationOnSubmit(values);
        }
        }
      >
        {({
          errors,
          values,
          handleChange,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit} className="d-flex justify-content-center align-items-center flex-column">
            <MDBRow className="text-center mb-3 mb-md-5">
              <h1>Додавання даних</h1>
            </MDBRow>

            <MDBContainer className="d-flex justify-content-center flex-column flex-md-row mb-3 mb-md-5">
              <MDBCol sm={12} md={5} className="m-2">
                <MDBRow className="mb-3">
                  <h4 className="fs-4">Введіть назву події</h4>
                  <InputField type="text" name="title" className="w-75 fs-5 input-styled rounded-3" placeholder="Назва події" />
                </MDBRow>

                <MDBRow className="mb-3">
                  <h4 className="fs-4">Введіть місце проведення події</h4>
                  <InputField type="text" name="place" className="w-75 input-styled rounded-3 fs-5" placeholder="Місце проведення" />
                </MDBRow>

                <MDBRow className="mb-3">
                  <h4 className="fs-4">Оберіть тип дозвілля</h4>
                  <Select
                    placeholder="Тип дозвілля"
                    options={options}
                    className="w-75 fs-5 ps-2 ms-1"
                    defaultValue={options[0]}
                    theme={theme => ({
                      ...theme,
                      borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary25: '#f0f0f0',
                        primary: 'black',
                      },
                    })}
                    onChange={e => values.type = e.value}
                  />
                </MDBRow>

                <MDBRow className="d-flex flex-column mb-3">
                  <h4 className="fs-4">Оберіть обкладинку події</h4>
                  <EventImageUploader name="coverFile" onChange={handleChange} />
                </MDBRow>
                <MDBRow className="d-flex flex-column">
                  <h4 className="fs-4">Оберіть картинку для слайдера</h4>
                  <EventImageUploader name="sliderFile" onChange={handleChange} />
                </MDBRow>
              </MDBCol>

              <MDBCol sm={12} md={5} className="m-2">
                <MDBRow className="mb-3">
                  <h4 className="fs-4">Оберіть дату та час події</h4>
                  <DateTime name="dateTime" />
                </MDBRow>
                <MDBRow className="mb-3">
                  <h4 className="fs-4">Введіть ціну</h4>
                  <MDBCol>
                    <p className="fs-5 me-2">Від</p>
                    <InputField name="priceFrom" className="input-styled rounded-3 fs-5 w-75" type="number" min={0} />
                  </MDBCol>
                  <MDBCol>
                    <p className="fs-5 me-2">До</p>
                    <InputField name="priceTo" className="input-styled rounded-3 fs-5 w-75" type="number" min={0} />
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                  <h4 className="fs-4">Введіть опис (необов&apos;язково)</h4>
                  <textarea onChange={handleChange} name="desc" className="admin__textarea-height input-styled rounded-3 ms-2" />
                </MDBRow>
              </MDBCol>
            </MDBContainer>

            <MDBRow className="d-flex m-auto">
              <MDBBtn type="submit" size="lg" className="py-3 px-5 rounded-3" color="dark">
                Відправити
              </MDBBtn>
            </MDBRow>
          </Form>
        )}
      </Formik>
    </MDBContainer>
  );
};
