/* eslint-disable arrow-body-style */
import React from 'react';
import {
  MDBContainer,
  MDBCol,
  MDBBtn,
} from 'mdb-react-ui-kit';

import './SubscribeEmailComp.css';
import bellIcon from '../../assets/images/logos/bell-icon.svg';

export const SubscribeEmailComp = () => {
  return (
    <MDBContainer className="subform__container-height w-100 d-flex flex-md-row flex-column bg-white rounded-8 justify-content-center p-3 mt-5">
      <MDBCol sm={12} md={6} className="d-flex flex-row justify-content-center align-items-center mb-3 mb-md-0">
        <img className="subform__icon-size" src={bellIcon} alt="" />
        <h1 className="fs-2 w-75">Будь в курсі актуальних подій</h1>
      </MDBCol>
      <MDBCol sm={12} md={6} className="d-flex flex-md-row flex-column justify-content-center align-items-center">
        <input className="subform__input-styling w-75 mx-3 mb-3 mb-md-0 rounded-6 fs-5 py-1 px-3 text-center" type="email" placeholder="Пошта" />
        <MDBBtn className="subform__button-styling rounded-7 text-break">Підписатися</MDBBtn>
      </MDBCol>
    </MDBContainer>
  );
};
