/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import {
  MDBNavbar,
  MDBContainer,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBCollapse,
  MDBIcon,
  MDBNavbarNav,
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
} from 'mdb-react-ui-kit';
import { NavSearchRow } from '../NavSearchRow';
import { PopupAuth } from '../PopupAuth';
import { leaveUser } from '../../redux/features/user/userSlice';
import { useDeviceWidth } from '../../hooks/useDeviceWidth';

import './PubNav.css';
import { HeaderLogo } from '../HeaderLogo/HeaderLogo';

const LogoutButton = ({ handleLogout }) => (
  <button type="button" onClick={handleLogout}>Вийти</button>
);

export const PubNav = () => {
  const dispatch = useDispatch();
  const { logged } = useSelector(state => state.user);

  const [showNavNoToggler, setShowNavNoToggler] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 991px)' });

  const handleLogout = () => {
    dispatch(leaveUser());
  };

  const screenWidth = useDeviceWidth();

  return (
    <MDBContainer className="my-3">
      <MDBNavbar
        expand="lg"
        light
        bgColor="none"
        className="shadow-0"
      >
        <MDBContainer className="d-flex justify-content-between shadow-0">
          {/* LOGO */}
          <MDBNavbarBrand href="/" className="m-0">
            <HeaderLogo screenWidth={screenWidth} />
          </MDBNavbarBrand>
          {/* TOGGLER BUTTON */}
          <MDBNavbarToggler
            type="button"
            data-target="#navbar"
            aria-controls="navbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowNavNoToggler(!showNavNoToggler)}
            className="ps-0"
          >
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler>
          {/* TOGGLER NAVIGATION MENU */}
          <MDBCollapse navbar show={showNavNoToggler}>
            {/* INPUT SEARCH */}
            <NavSearchRow />
            <MDBNavbarNav className="mr-auto mb-2 mb-lg-0 d-flex justify-content-center">
              {/* HEART */}
              <MDBNavbarItem className="me-5 d-flex flex-row align-items-center">
                <MDBNavbarLink
                  href="/favorite"
                  className="d-flex flex-row align-items-center"
                >
                  <MDBIcon far icon="heart" size="lg" className="text-danger" />
                  {isTabletOrMobile && <p className="m-0 ms-1">Улюблене</p>}
                </MDBNavbarLink>
              </MDBNavbarItem>
              {/* USER */}
              {logged
                ? (
                  <MDBNavbarItem className="me-5 d-flex align-items-center">
                    <LogoutButton handleLogout={handleLogout} />
                  </MDBNavbarItem>
                )
                : (
                  <MDBNavbarItem className="me-5 d-flex align-items-center">
                    <PopupAuth />
                  </MDBNavbarItem>
                )
              }
              {/* DROPBOX */}
              <MDBNavbarItem className="me-5 d-flex align-items-center">
                <MDBDropdown className="shadow-0">
                  <MDBDropdownToggle color="light">UA</MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem link href="/ua">
                      UA
                    </MDBDropdownItem>
                    <MDBDropdownItem link href="/en">
                      EN
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBContainer>
      </MDBNavbar>
    </MDBContainer>
  );
};
