import { createClient } from './createClient';
import { wait } from '../common/helpers/wait';

export const httpClient = createClient();

httpClient.interceptors.response.use(res => res.data);
httpClient.interceptors.request.use(async(req) => {
  await wait();

  return req;
});
