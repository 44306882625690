/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

const initialState = {
  events: [],
  currentPage: null,
  numberOfEvents: null,
  selectedEventId: null,
};

const eventsSlice = createSlice({
  name: 'eventsSlice',
  initialState,
  reducers: {
    setEvents: (state, action) => {
      state.events = action.payload.events;
      state.currentPage = action.payload.currentPage;
      state.numberOfEvents = action.payload.numberOfEvents;
    },
    setMoreEvents: (state, action) => {
      state.events = [...state.events, action.payload.events];
    },
    selectEvent: (state, action) => {
      state.selectedEventId = action.payload.selectedEventId;
    },
  },
});

export const {
  setEvents,
  setMoreEvents,
  selectEvent,
} = eventsSlice.actions;

export default eventsSlice.reducer;
