export const monthNames = ['січня', 'лютого', 'березня', 'квітня', 'травня', 'червня', 'липня', 'серпня', 'вересня', 'жовтня', 'листопада', 'грудня'];
export const dayNames = ['неділя', 'понеділок', 'вівторок', 'середа', 'четвер', "п'ятниця", 'субота'];

export const badgeTitles = ['Театри', 'Концерти', 'Stand-up', 'Музеї'];

export const nextMonths = ['Липень', 'Серпень', 'Вересень'];

export const phoneNumbers = [
  '+38(050)876-94-49',
  '+38(050)876-94-49',
  '+38(050)876-94-49',
];
