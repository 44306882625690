/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import {
  MDBRow,
  MDBCol,
  MDBSpinner,
} from 'mdb-react-ui-kit';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';

import { PurchaseButton } from '../PurchaseButton';
import { dateTimeChanger, normalizeDate } from '../../common/helpers/eventDateChanger';
import { publicService } from '../../services/publicService';
import { SubscribeEmailComp } from '../SubscribeEmailComp';
import { COVER_PATH } from '../../common/constants/main';
import image from '../../assets/images/img1.jpg';

import './EventItemInfo.css';

export const EventItemInfo = () => {
  const { id } = useParams();
  const [event, setEvent] = useState({});
  const [showMore, setShowMore] = useState(false);

  const { isLoading, isError, isSuccess, isFetching } = useQuery({
    queryKey: ['events', id],
    // eslint-disable-next-line space-before-function-paren
    queryFn: async () => {
      const { data } = await publicService.fetchEvent(id);

      setEvent(data);
    },
  });

  const eventdate = normalizeDate(dateTimeChanger(event.event_date));

  return (
    <>
      {isLoading ? <MDBSpinner color="dark" /> : (
        <>
          <MDBRow className="w-100 d-flex flex-row justify-content-between text-start m-0">
            <MDBCol sm={12} md={6} lg={5} xl={5} className="d-flex mb-md-0 mb-4">
              <img src={`${COVER_PATH}/${event.coverImage}`} alt="" className="iteminfo__image" />
            </MDBCol>
            <MDBCol sm={12} md={5} lg={6} xl={7} className="d-flex flex-column p-2">
              <h1 className="mb-3 text-break">{event.event_name}</h1>
              <p className="fs-4 lh-1 text-break">{eventdate}</p>
              <p className="fs-4 lh-1 text-break">{event.event_place}</p>
              <p className="fs-4 my-2 text-break">{event.event_price}₴</p>
              <a href="/" alt="">
                <PurchaseButton label="Купити квиток" />
              </a>
              {
                event.event_desc ? (
                  <div className="event_desc mt-5">
                    <p className="text-muted my-2">Опис:</p>
                    <p className="text-break">{showMore ? event.event_desc : (event.event_desc).substring(0, 250)}</p>
                    <button type="button" className="expansion__button" onClick={() => setShowMore(!showMore)}>
                      {showMore ? 'Сховати' : 'Докладніше'}
                    </button>
                  </div>
                )
                  : null
              }
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <SubscribeEmailComp />
          </MDBRow>
        </>
      )}
    </>
  );
};
